import React, { FC } from 'react';
import './WalmartCaseStudy.scss';
import SectionTitle from '../../components/common/SectionTitle';
import TextDescription from '../../components/common/TextDescription';
import OlenaPortnova from '../../assets/img/aboutUs/staff/PM/OlenaPortnova.jpeg';
import MykytaDidenko from '../../assets/img/aboutUs/staff/Developers/MykytaDidenko.jpeg';
import SergeyGorbachev from '../../assets/img/aboutUs/staff/Developers/SergeyGorbachev.jpeg';
import SerhiiUkrainets from '../../assets/img/aboutUs/staff/Developers/SerhiiUkrainets.png';
import Links from '../../components/pagesComponents/CaseStudy/Links';
import WalmartLayout from '../../components/PagesLayouts/WalmartLayout';
import PeolpeWhoMade from '../../components/pagesComponents/CaseStudy/PeolpeWhoMade';
import { TOurTeam, TText } from '../../types/types';
import walmartComps from '../../assets/img/CaseStudy/Walmart/walmart_comps.png';
import Span from '../../components/pagesComponents/CaseStudy/Span';
import FixedButton from '../../components/common/FixedButton';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const WallmartCaseStudy: FC = () => {
    const ourTalanted: TOurTeam = [
        { id: 0, name: 'Olena Drozd', position: 'TPM', img: OlenaPortnova },
        {
            id: 1,
            name: 'Nikita Didenko',
            position: 'Ruby Developer',
            img: MykytaDidenko,
        },
        {
            id: 2,
            name: 'Sergey Gorbachev',
            position: 'Technical Support Engineer',
            img: SergeyGorbachev,
        },
        {
            id: 3,
            name: 'Serhii Ukrainets',
            position: 'Frontend Developer',
            img: SerhiiUkrainets,
        },
    ];

    const outcomesText: TText = [
        {
            id: 0,
            text:
                'Blackbird built, launched, and continues to grow a commercially successful product on behalf of the client as a turnkey fully managed service.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'Currently, the client’s internal operations team uses it. They claim that the new software saves lots of time on routine tasks that take 95% percent of their time.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'Moreover, the developed internal ad tech product continues to grow to support even more features and use cases and becomes one of the most important internal systems within the company!',
            margin: 'mb',
        },
    ];

    const beginingStart: TText = [
        {
            id: 0,
            text:
                'In addition to that, the new, extended Statflo product team observed that some of the features have to be improved from both UX and optimization sides. Things like micro-delays while requests were traveling between one microservice to another sometimes killed the momentum disguised lightness and overall beauty of having a real-time 1:1 conversation with a business you are chatting with. The ability to quickly and easily react and reach a business via one-to-one texting is the core of 1:1 Retail Conversations™ product.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'All of the above plus some predictable product updates and scaling could bring even more complexity to the highly-demanded retail SaaS product, and inconveniences to Statflo customers. They love and value their customers so much, so they even generated an extra $28M of profit for them in 2019. Statflo aims to even larger figures, and the decision had been made, and the work on entirely rethought 1:1 SMS outreach SaaS product was started.',
            margin: 'mb',
        },
    ];

    const beginingEnd: TText = [
        {
            id: 0,
            text:
                'In addition to that, the new, extended Statflo product team observed that some of the features have to be improved from both UX and optimization sides. Things like micro-delays while requests were traveling between one microservice to another sometimes killed the momentum disguised lightness and overall beauty of having a real-time 1:1 conversation with a business you are chatting with. The ability to quickly and easily react and reach a business via one-to-one texting is the core of 1:1 Retail Conversations™ product.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'All of the above plus some predictable product updates and scaling could bring even more complexity to the highly-demanded retail SaaS product, and inconveniences to Statflo customers. They love and value their customers so much, so they even generated an extra $28M of profit for them in 2019. Statflo aims to even larger figures, and the decision had been made, and the work on entirely rethought 1:1 SMS outreach SaaS product was started.',
            margin: 'mb',
        },
    ];

    const rebuildStart: TText = [
        {
            id: 0,
            text:
                'You probably already heard of a thousand theoretical suggestions on “100% correct way to build Minimum Viable Product” (MVP) from the numerous articles on the Internet. However, life holds more variables worth taking into consideration. In our case, the foundation of the entirely rebuilt internal ad tech app and actual triggers of starting MVP was caused by different gradually-happening events.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'We actually started it 6 months before the great endeavor of rebuilding that entire ad tech software for the internal operations team. The client wanted primarily to remove the workload from the on-site development team for moderately used internal software, that’s why he chose us.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Since we were and still are engaged via a fully managed service model, we had more freedom from the beginning.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'The client’s internal operations team shared posts the pains and toughness of their work routine with us. We knew that Facebook was not the only platform they pushed the ads to. Google and Pinterest were on the list too.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                'We wanted to make the work with the proprietary ad tech software much more enjoyable for the client’s employees without stretching a budget, so we began to grind towards it.',
            margin: 'mb',
        },
        {
            id: 5,
            text:
                'Step-by-step the old ad module started to support more Facebook ad types and generally became more stable in working with Facebook API. We took it further and entirely changed the way ads were pushed to Facebook Ads Manager.',
            margin: 'mb',
        },
    ];

    const rebuildMiddle: TText = [
        {
            id: 0,
            text:
                'We also figured out one interesting yet a common way of how both Facebook and Pinterest handle URL fetching. We immediately utilized that discovery and added Pinterest support to the old ad module.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'The old ad module became the real-deal performer, not a bottleneck for internal operations after posts. Meanwhile, the admin panel and not suitable internal Ad Tech web app architecture were progress killers at that moment.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Matthew C., the co-founder of retail Ad Tech company, promptly saw a bigger picture and made the decision to completely redevelop the internal ad tech web app by utilizing posts the discoveries and achievements for the old ad module. That’s the official MVP beginning.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'It took 3 months of planning and rethinking. We carefully reviewed the work routine for the internal operations team, its possible improvements, and took into consideration future services from a company roadmap.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                'During this 3-month MVP stage, we drastically improved the UX of the admin panel. Every single user flow was carefully re-thought and iteratively designed to it’s perfection.',
            margin: 'mb',
        },
        {
            id: 5,
            text:
                'Moreover, we cut the overall number of user flows without sacrificing product functionality. For instance, duplicating the same product item ads to multiple ad platforms now is two-clicks-away instead of three separate user flows for each ad platform.',
            margin: 'mb',
        },
        {
            id: 6,
            text:
                'We didn’t forget about UI either. The new modern and sleek UI was designed to fit the client’s brand language.',
            margin: 'mb',
        },
    ];

    const rebuildEnd: TText = [
        {
            id: 0,
            text:
                'That was the end of the MVP stage and the beginning of the 9-month product development stage. Thanks to our project manager, consistent quality of work of our development, and QAs there’s nothing extraordinary we can share about this particular period of time. We did our best.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'It took 9 productive  moths and 7 blackbirders to develop the production-ready web app for retail Ad Tech company.',
            margin: 'mb',
        },
    ];
    useScrollToTop();

    return (
        <WalmartLayout>
            <div className="statflo-case-study__text">
                <SectionTitle type="primary" title="Satisfying outcomes" />
                {outcomesText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Links />
            </div>
            <div className="statflo-case-study__image">
                <img src={walmartComps} alt="" />
                <Span type="ui">The new modern UI design of the internal product</Span>
            </div>
            <PeolpeWhoMade page="/walmart-case-study/full-credits/" ourTalanted={ourTalanted} />
            <FixedButton />
        </WalmartLayout>
    );
};

export default WallmartCaseStudy;
